/*=====================
    Pagination Styles
=======================*/

.rn-pagination{
    ul{
        &.page-list{
            @extend %liststyle;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding: 5px 15px;
                line-height: 38px;
                width: 42px;
                height: 42px;
                border: 2px solid $theme-color-3;
                color: $theme-color-3;
                z-index: 2;
                font-weight: 600;
                border-radius: 4px;
                margin-left: 10px;

                &:hover {
                    color: $theme-color;
                    border-color: $theme-color;
                }

                &.active {
                    color: $theme-color;
                    border-color: $theme-color;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.line-separator {
    border-bottom: 1px solid #ebebeb;
}



